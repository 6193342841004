import {Injectable} from '@angular/core';
import {Constant} from '../shared/constant';
import {Resources} from './resources';

@Injectable()
export class RouteNameToResourceConverter {
    convert(routeName: string): Resources {
        switch (routeName) {
            case Constant.URL_ACCESS_LOG:
                return Resources.ACCESS_LOG;
            case Constant.URL_ACL:
                return Resources.ACL;
            case Constant.URL_APPLICATION:
                return Resources.APPLICATION;
            case Constant.URL_APPLICATION_INFO_FIELDS:
                return Resources.APPLICATION_INFO_FIELDS;
            case Constant.URL_BLACKLIST:
                return Resources.BLACK_LIST;
            case Constant.URL_ORGANISATION_BLACKLIST:
                return Resources.ORGANISATION_BLACK_LIST;
            case Constant.URL_CAMPAIGN:
                return Resources.CAMPAIGN;
            case Constant.URL_PWA:
                return Resources.PWA;
            case Constant.URL_PROXY:
                return Resources.PROXY;
            case Constant.URL_LINKULA:
                return Resources.LINKULA;
            case Constant.URL_GEO:
                return Resources.GEO;
            case Constant.URL_TOKEN:
                return Resources.TOKEN;
            case Constant.URL_OFFER:
                return Resources.OFFER;
            case Constant.URL_DIRECT_OFFER_WEBSITE:
                return Resources.DIRECT_OFFER_WEBSITE;
            case Constant.URL_POSTBACK_LOG:
                return Resources.POSTBACK_LOG;
            case Constant.URL_APP_CLICK:
                return Resources.CLICK;
            case Constant.URL_WEB_CLICK:
                return Resources.WEB_CLICK;
            case Constant.URL_PWA_CLICK:
                return Resources.PWA_CLICK;
            case Constant.URL_LINKULA_CLICK:
                return Resources.LINKULA_CLICK;
            case Constant.URL_SHOPIFY_CLICK:
                return Resources.SHOPIFY_CLICK;
            case Constant.URL_SETTINGS:
                return Resources.SETTINGS;
            case Constant.URL_MONITORING_SETTINGS:
                return Resources.MONITORING_SETTINGS;
            case Constant.URL_REPORT:
                return Resources.REPORT;
            case Constant.URL_APPLICATION_REPORT:
                return Resources.APPLICATION_REPORT;
            case Constant.URL_OFFER_REPORT:
                return Resources.OFFER_REPORT;
            case Constant.URL_OFFER_REPORT_BY_CAMPAIGN:
                return Resources.OFFER_REPORT;
            case Constant.URL_OFFER_REPORT_BY_APP:
                return Resources.OFFER_REPORT;
            case Constant.URL_GLOBAL_OFFER_REPORT:
                return Resources.GLOBAL_OFFER_REPORT;
            case Constant.URL_GLOBAL_OFFER_REPORT_BY_ENTITY:
                return Resources.GLOBAL_OFFER_REPORT;
            case Constant.URL_GLOBAL_OFFER_REPORT_BY_CAMPAIGN:
                return Resources.GLOBAL_OFFER_REPORT;
            case Constant.URL_OFFER_BY_MEDIABUYER_REPORT:
                return Resources.OFFER_REPORT_BY_MEDIABUYER;
            case Constant.URL_OFFER_BY_MEDIABUYER_REPORT_BY_APP:
                return Resources.OFFER_REPORT_BY_MEDIABUYER_BY_APP;
            case Constant.URL_GEO_REPORT:
                return Resources.GEO_REPORT;
            case Constant.URL_GEO_BY_CAMPAIGN_REPORT:
                return Resources.GEO_REPORT;
            case Constant.URL_GEO_BY_APP_REPORT:
                return Resources.GEO_REPORT;
            case Constant.URL_GEO_BY_OFFER_REPORT:
                return Resources.GEO_REPORT;
            case Constant.URL_GLOBAL_GEO_BY_CAMPAIGN_REPORT:
                return Resources.GLOBAL_GEO_REPORT;
            case Constant.URL_GLOBAL_GEO_BY_ENTITY_REPORT:
                return Resources.GLOBAL_GEO_REPORT;
            case Constant.URL_GLOBAL_GEO_REPORT:
                return Resources.GLOBAL_GEO_REPORT;
            case Constant.URL_GLOBAL_GEO_BY_OFFER_REPORT:
                return Resources.GLOBAL_GEO_REPORT;
            case Constant.URL_CAMPAIGN_REPORT:
                return Resources.CAMPAIGN_REPORT;
            case Constant.URL_CONVERSION_REPORT:
                return Resources.CONVERSION_REPORT;
            case Constant.URL_HOME_PAGE:
                return Resources.HOME_PAGE;
            case Constant.URL_DASHBOARD:
                return Resources.DASHBOARD;
            case Constant.URL_APP_EVENT:
                return Resources.EVENT;
            case Constant.URL_WEB_EVENT:
                return Resources.WEB_EVENT;
            case Constant.URL_PWA_EVENT:
                return Resources.PWA_EVENT;
            case Constant.URL_PUSH_EVENT:
                return Resources.PUSH_EVENT;
            case Constant.URL_LINKULA_EVENT:
                return Resources.LINKULA_EVENT;
            case Constant.URL_SHOPIFY_EVENT:
                return Resources.SHOPIFY_EVENT;
            case Constant.URL_POSTBACK_ROUTE_RULE:
                return Resources.POSTBACK_ROUTE_RULE;
            case Constant.URL_NEW_POSTBACK_ROUTE_RULE:
                return Resources.NEW_POSTBACK_ROUTE_RULE;
            case Constant.URL_POSTBACK_ROUTING_LOG:
                return Resources.POSTBACK_ROUTING_LOG;
            case Constant.URL_PUSH:
                return Resources.PUSH;
            case Constant.URL_PUSH_CAMPAIGN:
                return Resources.PUSH_CAMPAIGN;
            case Constant.URL_PUSH_SET:
                return Resources.PUSH_SET;
            case Constant.URL_PUSH_SCHEDULE_TEMPLATE:
                return Resources.PUSH_SCHEDULE_TEMPLATE;
            case Constant.URL_PUSH_TEMPLATE:
                return Resources.PUSH_TEMPLATE;
            case Constant.URL_ROLE:
                return Resources.ROLE;
            case Constant.URL_SECURITY_LINK:
                return Resources.SECURITY_LINK;
            case Constant.URL_USER:
                return Resources.USER;
            case Constant.URL_WEBPAGE:
                return Resources.WEBPAGE;
            case Constant.URL_VPN:
                return Resources.VPN;
            case Constant.URL_ANSIBLE_LOG:
                return Resources.ANSIBLE_LOG;
            case Constant.URL_WEBSITE:
                return Resources.WEBSITE;
            case Constant.URL_ID_BLACK_LIST:
                return Resources.ID_BLACK_LIST;
            case Constant.URL_WHITELIST:
                return Resources.WHITE_LIST;
            case Constant.URL_SERVICE_WHITE_LIST:
                return Resources.SERVICE_WHITE_LIST;
            case Constant.URL_MONITORING_WHITE_LIST:
                return Resources.MONITORING_WHITE_LIST;
            case Constant.URL_THREAT_MONITORING:
                return Resources.THREAT_MONITORING;
            case Constant.URL_CAMPAIGN_BY_GEO_REPORT:
                return Resources.CAMPAIGN_BY_GEO_REPORT;
            case Constant.URL_CAMPAIGN_BY_GEO_BY_OFFER_REPORT:
                return Resources.CAMPAIGN_BY_GEO_BY_OFFER_REPORT;
            case Constant.URL_PWA_CAMPAIGN_BY_GEO_BY_OFFER_REPORT:
                return Resources.PWA_CAMPAIGN_BY_GEO_BY_OFFER_REPORT;
            case Constant.URL_PWA_CAMPAIGN_BY_GEO_BY_UTM_REPORT:
                return Resources.PWA_CAMPAIGN_BY_GEO_BY_UTM_REPORT;
            case Constant.URL_DOW_CAMPAIGN_BY_GEO_BY_OFFER_REPORT:
                return Resources.DOW_CAMPAIGN_BY_GEO_BY_OFFER_REPORT;
            case Constant.URL_DOW_CAMPAIGN_BY_GEO_BY_UTM_REPORT:
                return Resources.DOW_CAMPAIGN_BY_GEO_BY_UTM_REPORT;
            case Constant.URL_PROFILE:
                return Resources.PROFILE;
            default:
                return null;
        }
    }
}
