export class Constant {

    public static readonly FIELD_WITHOUT_ACCESS = 'placeholder';

    public static readonly TABLE_SIZE = 20;
    public static readonly TABLE_PAGINATION_OPTIONS = [20, 30, 50, 100];

    public static readonly URL_WHITELIST = 'whitelist';
    public static readonly URL_SERVICE_WHITE_LIST = 'service_white_list';
    public static readonly URL_DASHBOARD = 'dashboard';
    public static readonly URL_MONITORING_WHITE_LIST = 'monitoring_white_list';
    public static readonly URL_THREAT_MONITORING = 'threat_monitoring';
    public static readonly URL_BLACKLIST = 'blacklist';
    public static readonly URL_LIST = 'list';
    public static readonly URL_ORGANISATION_BLACKLIST = 'organisation_blacklist';
    public static readonly URL_APPLICATION = 'application';
    public static readonly URL_APPLICATION_INFO_FIELDS = 'application_info_fields';
    public static readonly URL_GEO = 'geo';
    public static readonly URL_GLOBAL_GEO_REPORT = 'global_geo_report';
    public static readonly URL_GLOBAL_GEO_BY_ENTITY_REPORT = 'global_geo_by_entity_report';
    public static readonly URL_GLOBAL_GEO_BY_CAMPAIGN_REPORT = 'global_geo_by_campaign_report';
    public static readonly URL_GLOBAL_GEO_BY_OFFER_REPORT = 'global_geo_by_offer_report';
    public static readonly URL_SECURITY = 'security';
    public static readonly URL_MONITORING = 'monitoring';
    public static readonly URL_ACL = 'acl';
    public static readonly URL_ROLE = 'role';
    public static readonly URL_USER = 'user';
    public static readonly URL_SECURITY_LINK = 'security_link';
    public static readonly URL_OFFER = 'offer';
    public static readonly URL_DIRECT_OFFER_WEBSITE = 'direct_offer_website';
    public static readonly URL_PUSH_CAMPAIGN = 'push_campaign';
    public static readonly URL_PUSH_CONFIGURATION = 'push_configuration';
    public static readonly URL_PUSH = 'push';
    public static readonly URL_PUSH_SET = 'push_set';
    public static readonly URL_PUSH_TEMPLATE = 'push_template';
    public static readonly URL_PUSH_SCHEDULE_TEMPLATE = 'push_schedule_template';
    public static readonly URL_CAMPAIGN = 'campaign';
    public static readonly URL_PWA = 'pwa';
    public static readonly URL_CLICK = 'click';
    public static readonly URL_APP_CLICK = 'app_click';
    public static readonly URL_CLICK_EVENT = 'clicks_events';
    public static readonly URL_PWA_CLICK = 'pwa_click';
    public static readonly URL_WEB_CLICK = 'web_click';
    public static readonly URL_LINKULA_CLICK = 'linkula_click';
    public static readonly URL_SHOPIFY_CLICK = 'shopify_click';
    public static readonly URL_SETTINGS = 'settings';
    public static readonly URL_LINKULA = 'linkula';
    public static readonly URL_MONITORING_SETTINGS = 'monitoring_settings';
    public static readonly URL_REPORT = 'report';
    public static readonly URL_APPLICATION_REPORT = 'application_report';
    public static readonly URL_OFFER_REPORT = 'offer_report';
    public static readonly URL_OFFER_REPORT_BY_APP = 'offer_report_by_app';
    public static readonly URL_OFFER_REPORT_BY_CAMPAIGN = 'offer_report_by_campaign';
    public static readonly URL_GLOBAL_OFFER_REPORT = 'global_offer_report';
    public static readonly URL_GLOBAL_OFFER_REPORT_BY_ENTITY = 'global_offer_report_by_entity';
    public static readonly URL_GLOBAL_OFFER_REPORT_BY_CAMPAIGN = 'global_offer_report_by_campaign';
    public static readonly URL_OFFER_BY_MEDIABUYER_REPORT_BY_APP = 'offer_report_by_mediabuyer_by_app';
    public static readonly URL_OFFER_BY_MEDIABUYER_REPORT = 'offer_report_by_mediabuyer';
    public static readonly URL_GEO_REPORT = 'geo_report';
    public static readonly URL_CONVERSION_REPORT = 'conversion_report';
    public static readonly URL_CAMPAIGN_REPORT = 'campaign_report';
    public static readonly URL_CAMPAIGN_BY_GEO_REPORT = 'campaign_by_geo_report';
    public static readonly URL_CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'campaign_by_geo_by_offer_report';
    public static readonly URL_PWA_CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'pwa_campaign_by_geo_by_offer_report';
    public static readonly URL_PWA_CAMPAIGN_BY_GEO_BY_UTM_REPORT = 'pwa_campaign_by_geo_by_utm_report';
    public static readonly URL_DOW_CAMPAIGN_BY_GEO_BY_OFFER_REPORT = 'dow_campaign_by_geo_by_offer_report';
    public static readonly URL_DOW_CAMPAIGN_BY_GEO_BY_UTM_REPORT = 'dow_campaign_by_geo_by_utm_report';
    public static readonly URL_GEO_BY_OFFER_REPORT = 'geo_by_offer_report';
    public static readonly URL_GEO_BY_APP_REPORT = 'geo_by_app_report';
    public static readonly URL_GEO_BY_CAMPAIGN_REPORT = 'geo_by_campaign_report';
    public static readonly URL_EVENT = 'event';
    public static readonly URL_APP_EVENT = 'app_event';
    public static readonly URL_WEB_EVENT = 'web_event';
    public static readonly URL_PWA_EVENT = 'pwa_event';
    public static readonly URL_PUSH_EVENT = 'push_event';
    public static readonly URL_LINKULA_EVENT = 'linkula_event';
    public static readonly URL_SHOPIFY_EVENT = 'shopify_event';
    public static readonly URL_HOME_PAGE = '';
    public static readonly URL_POSTBACK = 'postback';
    public static readonly URL_TOKEN = 'token';
    public static readonly URL_POSTBACK_LOG = 'postback_logs';
    public static readonly URL_POSTBACK_ROUTE_RULE = 'postback_route_rule';
    public static readonly URL_NEW_POSTBACK_ROUTE_RULE = 'new_postback_route_rule';
    public static readonly URL_POSTBACK_ROUTING_LOG = 'postback_routing_log';
    public static readonly URL_PROXY = 'proxy';
    public static readonly URL_VPN = 'vpn';
    public static readonly URL_WEBPAGE = 'webpage';
    public static readonly URL_ANSIBLE_LOG = 'ansible_log';
    public static readonly URL_WEBSITE = 'website';
    public static readonly URL_ACCESS_LOG = 'access_log';
    public static readonly URL_ID_BLACK_LIST = 'id_black_list';
    public static readonly URL_PROFILE = 'profiles';
}
